import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';

// Routing
import { AppRoutingModule } from './app-routing.module';

// Environment
import { environment } from '../environments/environment';

// Firebase
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireFunctionsModule } from '@angular/fire/functions';
import { AngularFireStorageModule } from '@angular/fire/storage';

// Libraries 
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxMaskModule } from 'ngx-mask'

import { AppComponent } from './components/app.component';
import { SignupComponent } from './components/signup/signup.component';
import { EventsComponent } from './components/events/events.component';
import { HeaderComponent } from './components/header/header.component';

// Services
import { UiStoreService } from './common/services/ui-store.service';
import { EventService } from './common/services/event.service';
import { ImageService } from './common/services/image.service';
import { PaymentService } from './common/services/payment.service';
import { UserService } from './common/services/user.service';

// Components
import { HomeComponent } from './components/home/home.component';
import { DemoComponent } from './components/home/demo/demo.component';
import { AboutComponent } from './components/about/about.component';
import { PricingComponent } from './components/pricing/pricing.component';
import { PaymentRequestComponent } from './components/signup/payment-request/payment-request.component';
import { CreateAccountComponent } from './components/signup/create-account/create-account.component';
import { CreateEventComponent } from './components/signup/create-event/create-event.component';
import { LoginComponent } from './components/login/login.component';
import { AccountComponent } from './components/account/account.component';
import { AccountInfoComponent } from './components/account/account-info/account-info.component';
import { AccountEventsComponent } from './components/account/account-events/account-events.component';
import { ResetPasswordComponent } from './components/account/account-info/reset-password/reset-password.component';
import { UpdateDetailsComponent } from './components/account/account-info/update-details/update-details.component';
import { AccountBillingComponent } from './components/account/account-billing/account-billing.component';
import { EventsListComponent } from './components/account/account-events/events-list/events-list.component';
import { ImageComponent } from './components/events/image/image.component';

@NgModule({
    declarations: [
        AppComponent,
        SignupComponent,
        EventsComponent,
        HeaderComponent,
        HomeComponent,
        DemoComponent,
        AboutComponent,
        PricingComponent,
        PaymentRequestComponent,
        CreateAccountComponent,
        CreateEventComponent,
        LoginComponent,
        AccountComponent,
        AccountInfoComponent,
        AccountEventsComponent,
        ResetPasswordComponent,
        UpdateDetailsComponent,
        AccountBillingComponent,
        EventsListComponent,
        ImageComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        CommonModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        AngularFireModule.initializeApp(environment.firebase),
        AngularFirestoreModule,
        AngularFireAuthModule,
        AngularFireFunctionsModule,
        AngularFireStorageModule,
        NgbModule.forRoot(),
        NgxMaskModule.forRoot()
    ],
    providers: [
        UiStoreService,
        EventService,
        ImageService,
        PaymentService,
        UserService
    ],
    entryComponents: [
        DemoComponent
    ],
    bootstrap: [AppComponent]
})
export class AppModule { } 

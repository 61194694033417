import { Component, OnInit, OnDestroy } from '@angular/core';
import { Observable, Subject } from 'rxjs';

// Services
import { PaymentService } from '../../../common/services/payment.service';

// Models
import { Payment } from '../../../common/models/payment.model';

// Libraries
import { AngularFirestoreDocument, AngularFirestore } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireFunctions } from '@angular/fire/functions';

@Component({
    selector: 'app-account-billing',
    providers: [
        PaymentService
    ],
    templateUrl: './account-billing.component.html',
    styleUrls: ['./account-billing.component.scss']
})
export class AccountBillingComponent implements OnInit, OnDestroy {
    message: string;
    updating: boolean;

    private userDoc: AngularFirestoreDocument<any>;
    user$: Observable<any>;

    // Subject used to unsubscribe from everything
    private destroyed$: Subject<{}> = new Subject();

    constructor(
        private db: AngularFirestore,
        private afAuth: AngularFireAuth,
        private functions: AngularFireFunctions,
        private paymentService: PaymentService
    ) {

    }

    ngOnInit() {
        this.userDoc = this.db.doc<any>(`users/${this.afAuth.auth.currentUser.uid}`);
        this.user$ = this.userDoc.valueChanges();

        // Subscribing to payment token. Once payment is processed, this gets triggered.
        this.paymentService
            .token
            .takeUntil(this.destroyed$)
            .subscribe(async (payment: Payment) => {
                await this.updatePayment(payment.source);
            });
    }

    ngOnDestroy() {
        this.destroyed$.next();
        this.destroyed$.complete();
    }

    private async updatePayment(token: string) {
        try {
            if (!this.updating) {
                this.updating = true;

                this.message = "Updating payment info...";
                await this.functions.httpsCallable('updatePaymentMethod')(token).toPromise();
                this.message = "Successfully updated payment method!";

                this.updating = false;
            }
        } catch (error) {
            this.updating = false;
            this.message = error.message;
        }
    }
}

export const environment = {
    production: false,
    firebase: {
        apiKey: "AIzaSyBsMEye_V7skCmHm2D3VzsvgKfbXmlWruc",
        authDomain: "instant-event-feed-dev.firebaseapp.com",
        databaseURL: "https://instant-event-feed-dev.firebaseio.com",
        projectId: "instant-event-feed-dev",
        storageBucket: "instant-event-feed-dev.appspot.com",
        messagingSenderId: "586612279694"
    },
    stripeToken: "pk_test_ImEQT353dhV2gFLgsMGp1VT6",
    baseDomain: "https://dev.bashstream.com"
};

import { Component, OnInit } from '@angular/core';
import { DemoComponent } from '../home/demo/demo.component';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

    constructor(
        private modalService: NgbModal
    ) { }

    ngOnInit() {
    }

    open() {
        const modalRef = this.modalService.open(DemoComponent, { size: 'lg' });
    }

}

import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { FadeAnimation } from '../../../common/animations/fade.animation';
import { Subject } from 'rxjs';

import { AngularFireStorage } from '@angular/fire/storage';


@Component({
    selector: 'app-image',
    animations: [
        FadeAnimation
    ],
    templateUrl: './image.component.html',
    styleUrls: ['./image.component.scss']
})
export class ImageComponent implements OnInit, OnDestroy {
    private destroyed$: Subject<{}> = new Subject();

    @Input() fileName: string;

    thumbnailUrl: string;
    sourceUrl: string;

    constructor(
        private storage: AngularFireStorage,
    ) { }

    ngOnInit() {
        if (this.fileName) {
            const storageRef = this.storage
                .ref(`thumbnails/${this.fileName}`);

            storageRef.getDownloadURL()
                .takeUntil(this.destroyed$)
                .subscribe((url: string) => {
                    this.thumbnailUrl = url;
                });
        }
    }

    ngOnDestroy() {
        this.destroyed$.next();
        this.destroyed$.complete();
    }

    openSourceUrl() {
        if (this.sourceUrl) {
            window.open(this.sourceUrl, "_blank");
        } else {
            const storageRef = this.storage
                .ref(`uploads/${this.fileName}`);

            storageRef.getDownloadURL()
                .takeUntil(this.destroyed$)
                .subscribe((url: string) => {
                    this.sourceUrl = url;
                    window.open(this.sourceUrl, "_blank");
                });
        }
    }
}

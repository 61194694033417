import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';


@Component({
    selector: 'app-create-account',
    templateUrl: './create-account.component.html',
    styleUrls: ['./create-account.component.scss']
})
export class CreateAccountComponent implements OnInit {
    message: string;
    creating: boolean = false;
    created: boolean;
    account: FormGroup;

    private usersCollection: AngularFirestoreCollection<any>;

    constructor(
        private fb: FormBuilder,
        private afAuth: AngularFireAuth,
        private router: Router,
        private db: AngularFirestore
    ) {
        this.usersCollection = db.collection<any>('users');
    }

    ngOnInit() {
        this.account = this.fb.group({
            firstName: ['',
                [
                    Validators.required
                ]
            ],
            lastName: ['',
                [
                    Validators.required
                ]
            ],
            emailAddress: ['',
                [
                    Validators.required,
                    Validators.email
                ]
            ],
            password: ['',
                [
                    Validators.required
                ]
            ],
            confirmPassword: ['',
                [
                    Validators.required
                ]
            ]
        });
    }

    get passwordsMatch(): boolean {
        return this.account.controls.password.value === this.account.controls.confirmPassword.value;
    }

    async createAccount() {
        try {
            // For some reason, this method is being called twice without this little workaround.
            if (this.creating === false) {
                this.creating = true;
                // Creating actual account
                let user = await this.afAuth.auth.createUserWithEmailAndPassword(
                    this.account.get('emailAddress').value, this.account.get('password').value);

                await user.user.sendEmailVerification();

                // Updating profile information
                let displayName = `${this.account.get('firstName').value} ${this.account.get('lastName').value}`;

                await user.user.updateProfile({ displayName: displayName, photoURL: "" });

                this.created = true;
                this.creating = false;
                this.message = "Successfully created account!"

                this.router.navigate(['/account']);
            }
        } catch (error) {
            this.creating = false;
            this.message = error.message;
        }
    }
}

import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-account-info',
    templateUrl: './account-info.component.html',
    styleUrls: ['./account-info.component.scss']
})
export class AccountInfoComponent implements OnInit {

    constructor() { }

    ngOnInit() {
    }

}

import { Component, OnInit, Input } from '@angular/core';
import { FadeAnimation } from '../../../../common/animations/fade.animation';

import { environment } from '../../../../../environments/environment';

@Component({
    selector: 'app-events-list',
    animations: [
        FadeAnimation
    ],
    templateUrl: './events-list.component.html',
    styleUrls: ['./events-list.component.scss']
})
export class EventsListComponent implements OnInit {
    @Input() events: any[];

    constructor() { }

    ngOnInit() {

    }

    get filteredEvents() {
        return this.events.filter((event) => {
            if (event.startDate && event.endDate && event.status) return event;
        });
    }

    getUrl(eventCode): string {
        return `${environment.baseDomain}/e/${eventCode}`;
    }

    getPlainStatus(status): any {
        switch (status) {
            case 'created':
                return { status: 'Scheduled', badge: 'badge-primary' };
                break;

            case 'event-started':
                return { status: 'Started', badge: 'badge-success' };
                break;

            case 'event-done':
                return { status: 'Finished', badge: 'badge-secondary' };
                break;

            case 'event-expiring':
                return { status: 'Deleting Soon', badge: 'badge-warning' };
                break;

            default:
                return { status: 'Processing...', badge: 'badge-light' };
                break;
        }
    }
}
import { Component, OnInit, OnDestroy } from '@angular/core';

// Libraries
import { AngularFirestoreDocument, AngularFirestore } from '@angular/fire/firestore';

// Models
import { Pricing } from '../../common/models/pricing.model';
import { Observable } from 'rxjs';

@Component({
    selector: 'app-pricing',
    templateUrl: './pricing.component.html',
    styleUrls: ['./pricing.component.scss']
})
export class PricingComponent implements OnInit {
    // Where actual pricing data comes from
    private pricingDoc: AngularFirestoreDocument<Pricing>;
    pricing: Observable<Pricing>;

    constructor(
        private db: AngularFirestore
    ) {
        // Getting pricing data
        this.pricingDoc = this.db.doc<Pricing>('config/pricing');
        this.pricing = this.pricingDoc.valueChanges();
    }

    ngOnInit() {

    }
}

import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';

import { AngularFireAuth } from '@angular/fire/auth';
import { auth } from 'firebase/app';

import { isNullOrUndefined } from 'util';
import 'rxjs/add/operator/map';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {
    constructor(
        public afAuth: AngularFireAuth,
        private router: Router) { }

    canActivate(): Observable<boolean> {
        return this.afAuth.authState.map(auth => {
            if (isNullOrUndefined(auth)) {
                this.router.navigate(['/login']);
                return false;
            } else {
                return true;
            }
        });
    }
}

import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { Subject, Observable } from 'rxjs';
import 'rxjs/add/operator/takeUntil';
import { FadeAnimation } from '../../../common/animations/fade.animation';

// Validators
import { ValidateDate } from '../../../common/directives/date.validator';

// Libraries
import { AngularFirestore, AngularFirestoreDocument, AngularFirestoreCollection } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireFunctions } from '@angular/fire/functions';
import { JwtHelperService } from '@auth0/angular-jwt';
import * as moment from 'moment';

// Services
import { PaymentService } from '../../../common/services/payment.service';
import { EventService } from '../../../common/services/event.service';

// Models
import { Progress } from '../../../common/models/progress.model';
import { Pricing } from '../../../common/models/pricing.model';
import { SubmittedEvent } from '../../../common/models/submitted-event.model';
import { User } from '../../../common/models/user.model';
import { Payment } from '../../../common/models/payment.model';

const jwtHelper = new JwtHelperService();

@Component({
    selector: 'app-create-event',
    providers: [
        PaymentService
    ],
    animations: [
        FadeAnimation
    ],
    templateUrl: './create-event.component.html',
    styleUrls: ['./create-event.component.scss']
})
export class CreateEventComponent implements OnInit, OnDestroy {
    @Input() account: boolean = false;

    // Used during event creation
    eventProcessing: boolean = false;

    // Subject used to unsubscribe from everything
    private destroyed$: Subject<{}> = new Subject();

    creating: boolean = false; // Used to make sure "createEvent" isn't fired more than once.
    message: string; // Message text displayed for client information

    paymentSource: string; // Stripe payment token
    pricingToken: string;

    guestPrice: number;
    monthlyPrice: number;

    // Forms
    event: FormGroup;

    // What we observe for progress updates
    isCreated: boolean;

    constructor(
        private fb: FormBuilder,
        private db: AngularFirestore,
        public afAuth: AngularFireAuth,
        private functions: AngularFireFunctions,
        private paymentService: PaymentService,
        private eventService: EventService) {
    }

    ngOnInit() {
        this.afAuth.auth.currentUser

        // Subscribing to payment token. Once payment is processed, this gets triggered.
        this.paymentService
            .token
            .takeUntil(this.destroyed$)
            .subscribe((payment: Payment) => {
                // Should only be happening if the Stripe component was used.
                // Will run somewhere else if using a card on file.
                this.createEvent(payment);
            });

        // Subscribing to payment error.
        this.paymentService
            .error
            .takeUntil(this.destroyed$)
            .subscribe((error: string) => {
                this.message = error;
            });


        // For Event Details
        this.event = this.fb.group({
            eventName: ['',
                Validators.required
            ],
            eventDate: ['',
                [
                    Validators.required,
                    ValidateDate
                ]
            ],
            guestCount: ['',
                [
                    Validators.required,
                    Validators.min(100)
                ]
            ],
            firstName: ['',
                Validators.required
            ],
            lastName: ['',
                Validators.required
            ],
            emailAddress: ['',
                [
                    Validators.required,
                    Validators.email
                ]
            ],
            phoneNumber: ['',
                [
                    Validators.required,
                    Validators.minLength(10)
                ]
            ],
        });

        // Gets pricing and puts it in a JWT that expires in an hour. The goal here is to
        // eliminate the possibility of showing a user pricing on checkout that could change.
        this.functions.httpsCallable('getPricingToken')(null)
            .takeUntil(this.destroyed$)
            .subscribe((token) => {
                this.pricingToken = token;
                const decodedPricing = jwtHelper.decodeToken(token).data;

                this.monthlyPrice = decodedPricing.monthly;
                this.guestPrice = decodedPricing.guest;
            });
    }

    ngOnDestroy() {
        this.destroyed$.next();
        this.destroyed$.complete();
    }

    private async createEvent(payment: Payment) {
        try {
            // Goofy workaround so this doesn't run twice.
            if (!this.eventProcessing) {
                this.eventProcessing = true;

                this.message = 'Creating your event...';

                // Should include an 'useCardOnFile' once I get the button.
                const newEvent: SubmittedEvent = {
                    'name': this.event.value.eventName,
                    'paymentSource': payment.source,
                    'useCardOnFile': payment.useCardOnFile,
                    'pricingToken': this.pricingToken,
                    'startDate': moment(this.event.value.eventDate).toDate().toISOString(),
                    'guestCount': this.event.value.guestCount,
                    'customer': {
                        'firstName': this.event.value.firstName,
                        'lastName': this.event.value.lastName,
                        'phoneNumber': this.event.value.phoneNumber,
                        'emailAddress': this.event.value.emailAddress
                    }
                };

                // Creating event in the database with a function, which should return the eventId to listen to.
                const eventId = await this.functions.httpsCallable('createEvent')(newEvent).toPromise();

                // Subscribing to progress changes
                this.db.doc<Progress>(`progress/${eventId}`)
                    .valueChanges()
                    .takeUntil(this.destroyed$)
                    .subscribe((progress: Progress) => {
                        if (progress) {
                            this.message = progress.status;

                            if (progress.type === 'error') {
                                this.eventProcessing = false;
                            }

                            if (progress.type === 'success') {
                                this.isCreated = true;

                                this.eventProcessing = false;
                                this.event.reset();
                            }
                        }
                    });
            }

        } catch (error) {
            this.eventProcessing = false;
            this.message = error.message;
        }

    }

    getCart() {
        // Making sure event if filled out
        if (this.event.valid) {
            return this.eventService.getCheckout(
                this.event.value.guestCount,
                // Date is currently a text field
                new Date(this.event.value.eventDate),
                this.guestPrice,
                this.monthlyPrice);
        }

        return null;
    }

    getCardShadow(): string {
        if (!this.account) {
            return 'card-primary shadow-lg p-3 mb-5 bg-white';
        }
    }
}

import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

// Libraries
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';

// Models
import { Image } from '../../common/models/image.model';

// Services
import { EventService } from '../../common/services/event.service';
import { ImageService } from '../../common/services/image.service';

// Stores
import { Subject, Observable } from 'rxjs';

@Component({
    selector: 'app-events',
    templateUrl: './events.component.html',
    styleUrls: ['./events.component.scss']
})
export class EventsComponent implements OnInit, OnDestroy {
    private destroyed$: Subject<{}> = new Subject();

    downloading: boolean;
    eventName: string;

    private imageCollection: AngularFirestoreCollection<Image>;
    images: Image[] = [];

    constructor(
        private db: AngularFirestore,
        private route: ActivatedRoute,
        private eventService: EventService,
        private imageService: ImageService
    ) { }

    ngOnInit() {
        this.route.params.subscribe(params => {
            this.images = [];
            this.eventName = '';

            let eventCode = params.event;

            // Checking to make sure it's an event code, if not, make it one
            if (params.event.includes(' ')) {
                eventCode = this.eventService.generateEventCode(params.event);
            }

            this.imageCollection = this.db.collection<Image>(
                'images', ref => ref.where('eventCode', '==', eventCode).orderBy('date', 'desc'));

            this.imageCollection.valueChanges()
                .takeUntil(this.destroyed$)
                .subscribe(async (images: any) => {
                    // Checking for found events and getting full event name.
                    if (images[0]) {
                        this.eventName = images[0].eventName;

                        this.images = images;
                    } else {
                        this.eventName = 'No pictures have been posted, yet.';
                    }
                });
        });
    }

    ngOnDestroy() {
        this.destroyed$.next();
        this.destroyed$.complete();
    }

    trackByFn(index, item) {
        return item.name;
    }

    delay(ms: number) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    async download() {
        this.downloading = true;
        await this.imageService.download(this.images);
        this.downloading = false;
    }
}

import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/Subject';

import { Payment } from '../models/payment.model';

// Environment
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class PaymentService {
    public token = new Subject<Payment>();
    public error = new Subject<String>();

    public stripe = Stripe(environment.stripeToken);

    constructor() { }
}

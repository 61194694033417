import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AngularFireAuth } from '@angular/fire/auth';

@Component({
    selector: 'app-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
    message: string;
    changePasswordForm: FormGroup;

    constructor(
        private fb: FormBuilder,
        private afAuth: AngularFireAuth
    ) { }

    ngOnInit() {
        this.changePasswordForm = this.fb.group({
            currentPassword: ['',
                [
                    Validators.required
                ]
            ],
            newPassword: ['',
                [
                    Validators.required
                ]
            ],
            newPasswordRepeated: ['',
                [
                    Validators.required
                ]
            ],
        })
    }

    get passwordsMatch(): boolean {
        return this.changePasswordForm.controls.newPassword.value === this.changePasswordForm.controls.newPasswordRepeated.value;
    }

    async changePassword() {
        try {
            let user = this.afAuth.auth.currentUser;

            // Ensuring the session has been logged into recently
            await this.afAuth
                .auth
                .signInWithEmailAndPassword(
                    user.email,
                    this.changePasswordForm.get('currentPassword').value);

            await user.updatePassword(this.changePasswordForm.get('newPassword').value);

            this.message = 'Password successfully changed!';
        } catch (error) {
            this.message = error.message;
        }
    }

}

import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/Subject';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Observable } from 'rxjs/Rx';
import { AnonymousSubscription } from 'rxjs/Subscription';

@Injectable()
export class UiStoreService {
    public event = new Subject<String>();
    public emailVerified = new Subject<boolean>();

    constructor() { }
}

import { Injectable } from '@angular/core';
import { Checkout } from '../models/checkout.model';

@Injectable()
export class EventService {
    constructor() { }

    generateEventCode(name) {
        let eventCode = name.replace(/[^\w\s]/gi, '') // Remove funny characters
        eventCode = eventCode.split(' ').join('-'); // Replace all spaces with hiphens

        return eventCode.toLowerCase();
    }

    getCheckout(
        guestCount: number, startDate: Date,
        guestPrice: number,
        monthlyPrice: number): Checkout {

        // Getting guest price and summary
        const guestPriceTotal = guestPrice * guestCount;

        // Getting month price and summary
        const months = this.getMonthsUntilDate(startDate);
        const monthlyPriceTotal = months * monthlyPrice;

        // Setting up new object and totalling everything
        let checkout: Checkout = {
            guestPrice: '$' + guestPriceTotal.toFixed(2),
            guestSummary: guestCount + ' guests ($' + guestPrice.toFixed(2) + ' per guest)',
            monthPrice: '$' + monthlyPriceTotal.toFixed(2),
            monthSummary: months + ' month(s) ($' + monthlyPrice.toFixed(2) + ' per month)',
            totalPrice: '$' + (guestPriceTotal + monthlyPriceTotal).toFixed(2)
        };

        return checkout;
    }

    private getMonthsUntilDate(startDate: Date) {
        let today = new Date();
        today.setUTCHours(0, 0, 0, 0);

        const days = this.daysBetween(today, startDate);
        let months = days / 30;

        // Rounding to the highest month count
        months = Math.ceil(months);

        return months;
    }

    // Determining if the date is valid
    isInPast(startDate: Date) {
        let today = new Date();
        today.setUTCHours(0, 0, 0, 0);

        if ((startDate > today)) {
            return false;
        }

        return true;
    }

    // Source: https://www.quora.com/How-do-I-get-the-number-of-days-between-two-dates-in-Javascript
    daysBetween(startDate, endDate) {
        // The number of milliseconds in one day
        var oneDay = 1000 * 60 * 60 * 24

        // Convert both dates to milliseconds
        var startDateMs = startDate.getTime();
        var endDateMs = endDate.getTime();

        // Calculate the difference in milliseconds
        var differenceMs = Math.abs(startDateMs - endDateMs);

        // Convert back to days and return
        return Math.round(differenceMs / oneDay);
    };
}

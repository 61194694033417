import { Component, OnInit } from '@angular/core';
import { FadeAnimation } from '../common/animations/fade.animation';

@Component({
    selector: 'app-root',
    animations: [
        FadeAnimation
    ],
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    title = 'Bashstream';

    constructor(
    ) {
    }

    ngOnInit() {
    }

    getRouteAnimation(outlet) {
        return outlet.activatedRouteData.state
    }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

// Libraries
import saveAs from 'file-saver';
import * as JSZip from 'jszip';

// Models
import { Image } from '../models/image.model';
import { AngularFireStorage } from '@angular/fire/storage';

@Injectable()
export class ImageService {
    public zip: JSZip = new JSZip();

    constructor(
        private http: HttpClient,
        private storage: AngularFireStorage
    ) { }

    public async download(images: Image[]) {
        let count = 0;

        for (let image of images) {

            // Going through each image, getting it's download URL, downloading and adding to a zip file.
            this.storage
                .ref(`uploads/${image.name}`)
                .getDownloadURL()
                .subscribe((url: string) => {
                    this.downloadUrl(url)
                        .subscribe(async (data: Blob) => {
                            this.zip.file(image.name, data, { binary: true });
                            count++;

                            if (count == images.length) {
                                let zipFile = await this.zip.generateAsync({ type: "blob" });
                                saveAs(zipFile, `${image.eventCode}.zip`);
                            }
                        });
                });
        }
    }

    // Downloads directly from Azure after running getDownloadLink()
    private downloadUrl(url: string) {
        return this.http
            .get(url,
                {
                    responseType: 'blob'
                });
    }
}
import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AngularFireAuth } from '@angular/fire/auth';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
    @ViewChild('email') emailElement: ElementRef;

    error: boolean;
    signin: FormGroup;

    constructor(
        private fb: FormBuilder,
        private afAuth: AngularFireAuth,
        private router: Router
    ) { }

    ngOnInit() {
        this.signin = this.fb.group({
            emailAddress: ['',
                [
                    Validators.required
                ]
            ],
            password: ['',
                [
                    Validators.required
                ]
            ]
        });
    }

    async login() {
        try {
            await this.afAuth
                .auth
                .signInWithEmailAndPassword(
                    this.signin.get('emailAddress').value,
                    this.signin.get('password').value);

            this.router.navigate(['/account']);
        } catch (error) {
            this.signin.reset();
            this.emailElement.nativeElement.focus();

            this.error = true;
        }

    }
}

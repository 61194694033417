import { Injectable } from '@angular/core';

@Injectable()
export class UserService {
    constructor() { }

    isFreeUser(user: any) {
        if (!user) return false;

        const freeUserClasses = [
            'admin',
            'free'
        ];

        return freeUserClasses.includes(user.class);
    }
}

import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

// Libraries
import { AngularFirestore, AngularFirestoreDocument, AngularFirestoreCollection } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';

@Component({
    selector: 'app-account-events',
    templateUrl: './account-events.component.html',
    styleUrls: ['./account-events.component.scss']
})
export class AccountEventsComponent implements OnInit, OnDestroy {
    private destroyed$: Subject<{}> = new Subject();

    private eventsCollection: AngularFirestoreCollection<any[]>;
    events: any[] = [];

    constructor(
        public db: AngularFirestore,
        public afAuth: AngularFireAuth,
    ) { }

    ngOnInit() {
        this.eventsCollection = this.db.collection<any>(
            'events', ref => ref.where('uid', '==', this.afAuth.auth.currentUser.uid).orderBy('startDate', 'desc'));

        this.eventsCollection.valueChanges()
            .takeUntil(this.destroyed$)
            .subscribe((events: any[]) => {
                this.events = events;
            })
    }

    ngOnDestroy() {
        this.destroyed$.next();
        this.destroyed$.complete();
    }

}

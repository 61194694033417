import { Component, AfterViewInit, ViewChild, OnInit, Input, OnDestroy } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { AngularFirestoreDocument, AngularFirestore } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';

// Services
import { PaymentService } from '../../../common/services/payment.service'
import { UserService } from '../../../common/services/user.service';

@Component({
    selector: 'payment-request',
    templateUrl: './payment-request.component.html',
    styleUrls: ['./payment-request.component.scss']
})
export class PaymentRequestComponent implements OnInit, OnDestroy, AfterViewInit {
    private destroyed$: Subject<{}> = new Subject();

    // Signals that we don't want to enable some features of the payment control.
    @Input() updatesOnly = false;
    @Input() enabled: boolean;
    // Set if an event is being created or the payment method is being updated.
    @Input() creatingOrUpdating: boolean;

    elements: any;
    card: any;
    processing: boolean = false;
    useSavedPayment: boolean = false;

    paymentSource: any;
    isFreeUser: boolean;

    // Forms
    stripe: FormGroup;

    // Element used to mount the button
    @ViewChild('payElement') payElement;

    constructor(
        private fb: FormBuilder,
        private db: AngularFirestore,
        private afAuth: AngularFireAuth,
        private paymentService: PaymentService,
        private userService: UserService) { }

    ngOnInit() {
        // For Stripe
        this.stripe = this.fb.group({
            name: ['', [Validators.required]]
        });

        // Watching for authorization so we can offer saved payment methods
        this.afAuth.user
            .takeUntil(this.destroyed$)
            .subscribe((afUser: firebase.User) => {
                if (afUser) {
                    this.db.doc(`users/${afUser.uid}`).valueChanges().subscribe((user: any) => {
                        this.paymentSource = user.stripeCustomer.source;

                        this.isFreeUser = this.userService.isFreeUser(user);
                    });
                }
            });
    }

    ngOnDestroy() {
        this.destroyed$.next();
        this.destroyed$.complete();
    }

    ngAfterViewInit() {
        this.mountCard();
    }

    mountCard() {
        this.elements = this.paymentService.stripe.elements();

        this.card = this.elements.create('card', {
            style: {
                base: {
                    lineHeight: '1.429'
                }
            }
        });

        this.card.mount(this.payElement.nativeElement);
    }

    onUseSavedMethod(e) {
        this.useSavedPayment = e.target.checked;
    }

    async processCreditCard() {
        if (!this.processing) {
            this.processing = true;
            var result = await this.paymentService.stripe.createToken(this.card);

            if (result.token) {
                this.paymentService.token.next({
                    source: result.token.id,
                    useCardOnFile: false
                });

            } else if (result.error) {
                this.paymentService.error.next(result.error.message);
            }

            this.processing = false;
        }
    }

    processSavedPayment() {
        if (!this.processing) {
            this.processing = true;

            this.paymentService.token.next({
                source: null,
                useCardOnFile: true
            });

            this.processing = false;
        }
    }

    processFreeEvent() {
        if (!this.processing) {
            this.processing = true;

            this.paymentService.token.next({
                source: null,
                useCardOnFile: false
            });

            this.processing = false;
        }
    }

    async checkout() {
        if (this.isFreeUser) {
            this.processFreeEvent();
        }
        else if (this.useSavedPayment) {
            this.processSavedPayment();
        } else {
            await this.processCreditCard();
        }
    }

    get enablePayment(): boolean {
        return !this.processing && !this.creatingOrUpdating && this.enabled;
    }

    get label(): string {
        if (this.creatingOrUpdating) {
            return this.updatesOnly ? "Updating..." : "Creating...";
        } else {
            return this.updatesOnly ? "Update" : "Create";

        }
    }
}
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AngularFireAuth } from '@angular/fire/auth';
import { Subject } from 'rxjs';

// Models
import { User } from '../../../../common/models/user.model';

@Component({
    selector: 'app-update-details',
    templateUrl: './update-details.component.html',
    styleUrls: ['./update-details.component.scss']
})
export class UpdateDetailsComponent implements OnInit {
    // Subject used to unsubscribe from everything
    private destroyed$: Subject<{}> = new Subject();

    savingAttempted: boolean = false;
    message: string;
    details: FormGroup;

    constructor(
        private fb: FormBuilder,
        private afAuth: AngularFireAuth,
        private router: Router
    ) {

    }

    ngOnInit() {
        this.details = this.fb.group({
            firstName: ['',
                [
                    Validators.required
                ]
            ],
            lastName: ['',
                [
                    Validators.required
                ]
            ],
            emailAddress: ['',
                [
                    Validators.required,
                    Validators.email
                ]
            ],
            confirmEmailAddress: ['',
                [
                    Validators.required,
                    Validators.email
                ]
            ]
        });
    }

    ngOnDestroy() {
        this.destroyed$.next();
        this.destroyed$.complete();
    }

    get emailsMatch(): boolean {
        return this.details.controls.emailAddress.value === this.details.controls.confirmEmailAddress.value;
    }

    async save() {
        try {
            // For some reason, this method is being called twice without this little workaround.
            if (!this.savingAttempted) {
                this.savingAttempted = true;

                // Updating profile information
                let displayName = `${this.details.get('firstName').value} ${this.details.get('lastName').value}`;

                await this.afAuth.auth.currentUser.updateProfile({ displayName: displayName, photoURL: "" });

                // If the email hasn't changed, don't force an update.
                if (this.afAuth.auth.currentUser.email !== this.details.get('emailAddress').value) {
                    await this.afAuth.auth.currentUser.updateEmail(this.details.get('emailAddress').value);
                    await this.afAuth.auth.currentUser.sendEmailVerification();

                    await this.afAuth.auth.currentUser.reload();
                } else {
                    this.message = 'Successfully updated account information!';
                }

                this.savingAttempted = false;
            }

        } catch (error) {
            this.savingAttempted = false;
            this.message = error.message;
        }
    }

}

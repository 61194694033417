import { Component, OnInit, HostListener } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';

@Component({
    selector: 'app-account',
    templateUrl: './account.component.html',
    styleUrls: ['./account.component.scss']
})
export class AccountComponent implements OnInit {
    message: string;

    constructor(
        private afAuth: AngularFireAuth,
        private router: Router
    ) { }

    @HostListener('window:focus', ['$event'])
    async onFocus(event: any) {
        await this.afAuth.auth.currentUser.reload();

        if (!this.afAuth.auth.currentUser.uid) this.router.navigate(['/login']);

        if (this.verified) {
            await this.afAuth.auth.currentUser.getIdToken(true);
            this.message = '';
        }
    }

    ngOnInit() { }

    get verified(): boolean {
        return this.afAuth.auth.currentUser.emailVerified;
    }

    async resendVerification() {
        this.afAuth.auth.currentUser.sendEmailVerification();
        this.message = "Verification sent!"
    }
}

import { ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from './common/auth.guard';

import { SignupComponent } from './components/signup/signup.component';
import { CreateEventComponent } from './components/signup/create-event/create-event.component';
import { CreateAccountComponent } from './components/signup/create-account/create-account.component';

import { EventsComponent } from './components/events/events.component';
import { HomeComponent } from './components/home/home.component';
import { AboutComponent } from './components/about/about.component';
import { PricingComponent } from './components/pricing/pricing.component';
import { LoginComponent } from './components/login/login.component';
import { AccountComponent } from './components/account/account.component';
import { AccountEventsComponent } from './components/account/account-events/account-events.component';
import { AccountInfoComponent } from './components/account/account-info/account-info.component';

const routes: Routes = [
    {
        path: '',
        component: HomeComponent
    },
    {
        path: 'about',
        component: AboutComponent
    },
    {
        path: 'pricing',
        component: PricingComponent
    },
    {
        path: 'signup',
        component: SignupComponent,
        children: [
            { path: '', redirectTo: 'event', pathMatch: 'full' },
            { path: 'event', component: CreateEventComponent },
            { path: 'account', component: CreateAccountComponent }
        ]
    },
    {
        path: 'login',
        component: LoginComponent
    },
    {
        path: 'account',
        component: AccountComponent,
        canActivate: [AuthGuard],
        children: [
            { path: '', redirectTo: 'events', pathMatch: 'full' },
            { path: 'events', component: AccountEventsComponent },
            { path: 'info', component: AccountInfoComponent }
        ]
    },
    {
        path: 'e/:event',
        component: EventsComponent,
        data: {
            state: 'events'
        }
    },
    {
        path: '**',
        redirectTo: ''
    }
];

export const appRoutingProvider: any[] = [];

export const AppRoutingModule: ModuleWithProviders = RouterModule.forRoot(routes);

import { AbstractControl } from '@angular/forms';

export function ValidateDate(control: AbstractControl): { [key: string]: boolean } | null {
    if (control.value) {
        let today = new Date();

        const dateArray = control.value.split('-');
        const year = dateArray[0];
        const month = parseInt(dateArray[1], 10) - 1; // Something about being a month off
        const day = dateArray[2];

        let startDate = new Date(year, month, day);

        if (startDate < today) {
            return { 'validDate': true };
        }
    }

    return null;
} 
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EventService } from '../../common/services/event.service';
import { AngularFireAuth } from '@angular/fire/auth';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
    eventCode: string = '';

    constructor(
        private router: Router,
        public afAuth: AngularFireAuth,
        public eventService: EventService
    ) { }

    ngOnInit() {

    }

    logout() {
        this.afAuth.auth.signOut();
        this.router.navigate(['/login']);
    }

    get email(): string {
        return this.afAuth.auth.currentUser.email;
    }
}
